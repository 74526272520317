<div class="row justify-content-center mt-3">
  <div class="col-auto my-fs-2">
    <h3>{{ objective.name | ms }}</h3>
  </div>
</div>
<div class="row align-items-center mt-3" [formGroup]="form">
  <div class="col">
    <div class="d-flex w-100 align-items-center">
      <ic2-input-text-delayed
        class="input-group"
        style="width: 13rem"
        #f
        type="text"
        placeholder="{{ 'global.Recherche' | translate }}"
        formControlName="recherche"
      ></ic2-input-text-delayed>
    </div>
  </div>
  <div class="col-auto align-self-end my-fs-2">{{ 'global.Résultats :' | translate }} {{ count }}</div>
</div>
<ic2-my-table class="d-block mt-2" [rows]="data" [clientSort]="true" (orderChange)="orderChange($event)" [(order)]="order" #table>
  <ic2-my-column orderName="lastName" [name]="'ema.kpi.list.Collaborateur' | translate">
    <td class="text-nowrap" *tpl="table; let r" [ngStyle]="getStyle(r)">{{ r.lastName }} {{ r.firstName }}</td>
  </ic2-my-column>
  <!--
    <ic2-my-column orderName="manager" [name]="'ema.kpi.list.Superviseurs' | translate">
    <td class="text-start" *tpl="table; let r" [ngStyle]="getStyle(r)">
      {{ r.manager }}
    </td>
  </ic2-my-column>
-->
  <ic2-my-column orderName="r/o" [name]="'ema.kpi.list.Kpi' | translate">
    <td class="text-nowrap" *tpl="table; let r" [ngStyle]="getStyle(r)">
      <span [ngbTooltip]="getTooltip(r)">
        {{ getResult(r) | number : '1.0-2' }}
        {{ objective.type === EMAObjectiveType.TEXTUEL ? '%' : (objective.unit | ms) }}
      </span>
    </td>
  </ic2-my-column>
  <ic2-my-column orderName="creationDate" [name]="'ema.kpi.list.Date dernière mise à jour' | translate">
    <td class="text-nowrap" *tpl="table; let r" [ngStyle]="getStyle(r)">
      {{ r.creationDate | localizedDate }}
    </td>
  </ic2-my-column>
</ic2-my-table>

<div class="row mt-1 mb-3" *ngIf="loading">
  <div class="col text-center">
    <i class="fa fa-spinner spinning fa-2x"></i>
  </div>
</div>

<div class="row justify-content-center mt-3" *ngIf="!loading">
  <div class="col-auto">
    <ngb-pagination
      [collectionSize]="count"
      [(page)]="page"
      [pageSize]="nbPerPage"
      [maxSize]="3"
      [rotate]="true"
      (pageChange)="reload()"
      [disabled]="loading"
    ></ngb-pagination>
  </div>
</div>
